//libraries
import * as React from "react";
import { Tab } from "@headlessui/react";

interface PersonProps {
  rankingStatus: string;
  name: string;
  nationality: string;
  club: string;
}

interface TabPanelProps {
  people: Array<PersonProps>;
  fieldName: string;
  showMore: boolean;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props {
  title: string;
  tabLists: Array<string>;
  tabPanels: Array<TabPanelProps>;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Ranking: React.FC<Props> = ({ title, tabLists, tabPanels }) => {
  return (
    <div className="py-8 mb-6 mx-auto">
      <div className="my-0 mx-auto max-w-6xl px-4 sm:px-6 md:px-8">
        <div className="flex flex-col">
          <div className="font-bold py-8 text-2xl text-white text-center bg-green">
            {title}
          </div>
          <Tab.Group>
            <Tab.List>
              {tabLists.map((tab) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    classNames(
                      `bg-white text-center text-xs md:text-sm p-4`,
                      selected
                        ? "border-l-2 border-r-2 border-green bg-light text-green"
                        : ""
                    )
                  }
                >
                  {tab}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {tabPanels.map((tabPanel) => (
                <Tab.Panel key={tabPanel.fieldName} className="w-full">
                  <div className="align-middle inline-block min-w-full -my-2">
                    <div className="shadow overflow-hidden border-b border-gray-200">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-blue-500">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-tighter"
                            >
                              Ranking
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-tighter"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-tighter hidden lg:table-cell"
                            >
                              Nationality
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-tighter hidden lg:table-cell"
                            >
                              Club
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3 text-left text-xs font-medium text-white uppercase tracking-tighter"
                            >
                              Points
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {tabPanel.people.map(
                            (person: PersonProps, index: number) => (
                              <tr key={person.name}>
                                <td className="px-3 py-4 whitespace-nowrap text-xs md:text-sm font-medium text-gray-900">
                                  {index + 1}
                                </td>
                                <td className="px-3 py-4 whitespace-wrap text-xs md:text-sm text-gray-500">
                                  {person.name}
                                </td>
                                <td className="px-3 py-4 whitespace-nowrap text-xs text-gray-500 hidden lg:table-cell">
                                  {person.nationality}
                                </td>
                                <td className="px-3 py-4 whitespace-nowrap text-xs text-gray-500 hidden lg:table-cell">
                                  {person.club}
                                </td>
                                <td className="px-3 py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">
                                  {person[tabPanel.fieldName]}
                                </td>
                              </tr>
                            )
                          )}
                          {tabPanel.people.length > 9 && (
                            <tr>
                              <td className="text-center" colSpan={6}>
                                <button
                                  className="p-2 text-center w-full text-green"
                                  onClick={() =>
                                    tabPanel.setShowMore(
                                      (showMore) => !showMore
                                    )
                                  }
                                >
                                  {tabPanel.showMore ? "Less" : "More"}
                                </button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};
export default Ranking;
