//libraries
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
//containers
import Layout from "../containers/layout";
//components
import Seo from "../components/seo";
import Ranking from "../components/rankings/ranking";

const Rankings: React.FC = () => {
  const [showMoreMen, setShowMoreMen] = React.useState(false);
  const [showMoreWomen, setShowMoreWomen] = React.useState(false);
  const [showMoreBoysUnder16, setShowMoreBoysUnder16] = React.useState(false);
  const [showMoreGirlsUnder16, setShowMoreGirlsUnder16] = React.useState(false);
  const [showMoreBoysUnder14, setShowMoreBoysUnder14] = React.useState(false);
  const [showMoreGirlsUnder14, setShowMoreGirlsUnder14] = React.useState(false);
  const [showMoreBoysUnder12, setShowMoreBoysUnder12] = React.useState(false);
  const [showMoreGirlsUnder12, setShowMoreGirlsUnder12] = React.useState(false);

  const {
    womenPoints,
    womenUnder16Points,
    womenUnder14Points,
    womenUnder12Points,
    menPoints,
    menUnder16Points,
    menUnder14Points,
    menUnder12Points,
  } = useStaticQuery(
    graphql`
      query {
        womenPoints: allPeopleCsv(filter: { women_points: { ne: "" } }) {
          nodes {
            name
            nationality
            club
            women_points
          }
        }
        womenUnder16Points: allPeopleCsv(
          filter: { women_u16_points: { ne: "" } }
        ) {
          nodes {
            name
            nationality
            club
            women_u16_points
          }
        }
        womenUnder14Points: allPeopleCsv(
          filter: { women_u14_points: { ne: "" } }
        ) {
          nodes {
            name
            nationality
            club
            women_u14_points
          }
        }
        womenUnder14Points: allPeopleCsv(
          filter: { women_u14_points: { ne: "" } }
        ) {
          nodes {
            name
            nationality
            club
            women_u14_points
          }
        }
        womenUnder12Points: allPeopleCsv(
          filter: { women_u12_points: { ne: "" } }
        ) {
          nodes {
            name
            nationality
            club
            women_u12_points
          }
        }
        menPoints: allPeopleCsv(filter: { men_points: { ne: "" } }) {
          nodes {
            name
            nationality
            club
            men_points
          }
        }
        menUnder16Points: allPeopleCsv(filter: { men_u16_points: { ne: "" } }) {
          nodes {
            name
            nationality
            club
            men_u16_points
          }
        }
        menUnder14Points: allPeopleCsv(filter: { men_u14_points: { ne: "" } }) {
          nodes {
            name
            nationality
            club
            men_u14_points
          }
        }
        menUnder12Points: allPeopleCsv(filter: { men_u12_points: { ne: "" } }) {
          nodes {
            name
            nationality
            club
            men_u12_points
          }
        }
      }
    `
  );

  const women = womenPoints.nodes.sort(
    (a, b) => b.women_points - a.women_points
  );
  const girlsUnder16 = womenUnder16Points.nodes.sort(
    (a, b) => b.women_u16_points - a.women_u16_points
  );
  const girlsUnder14 = womenUnder14Points.nodes.sort(
    (a, b) => b.women_u14_points - a.women_u14_points
  );
  const girlsUnder12 = womenUnder12Points.nodes.sort(
    (a, b) => b.women_u12_points - a.women_u12_points
  );
  const men = menPoints.nodes.sort((a, b) => b.men_points - a.men_points);
  const boysUnder16 = menUnder16Points.nodes.sort(
    (a, b) => b.men_u16_points - a.men_u16_points
  );
  const boysUnder14 = menUnder14Points.nodes.sort(
    (a, b) => b.men_u14_points - a.men_u14_points
  );
  const boysUnder12 = menUnder12Points.nodes.sort(
    (a, b) => b.men_u12_points - a.men_u12_points
  );

  return (
    <Layout>
      <Seo
        title="Rankings"
        description="best players and memories in the seychelles tennis association"
      />
      <>
        <Ranking
          title="National Ranking"
          tabLists={["Women", "Men"]}
          tabPanels={[
            {
              people: showMoreWomen ? women : women.slice(0, 10),
              fieldName: "women_points",
              showMore: showMoreWomen,
              setShowMore: setShowMoreWomen,
            },
            {
              people: showMoreMen ? men : men.slice(0, 10),
              fieldName: "men_points",
              showMore: showMoreMen,
              setShowMore: setShowMoreMen,
            },
          ]}
        />
        <Ranking
          title="National Junior Ranking"
          tabLists={[
            "Girls 16 & Under",
            "Boys 16 & Under",
            "Girls 14 & Under",
            "Boys 14 & Under",
            "Girls 12 & Under",
            "Boys 12 & Under",
          ]}
          tabPanels={[
            {
              people: showMoreGirlsUnder16
                ? girlsUnder16
                : girlsUnder16.slice(0, 10),
              fieldName: "women_u16_points",
              showMore: showMoreGirlsUnder16,
              setShowMore: setShowMoreGirlsUnder16,
            },
            {
              people: showMoreBoysUnder16
                ? boysUnder16
                : boysUnder16.slice(0, 10),
              fieldName: "men_u16_points",
              showMore: showMoreBoysUnder16,
              setShowMore: setShowMoreBoysUnder16,
            },
            {
              people: showMoreGirlsUnder14
                ? girlsUnder14
                : girlsUnder14.slice(0, 10),
              fieldName: "women_u14_points",
              showMore: showMoreGirlsUnder14,
              setShowMore: setShowMoreGirlsUnder14,
            },
            {
              people: showMoreBoysUnder14
                ? boysUnder14
                : boysUnder14.slice(0, 10),
              fieldName: "men_u14_points",
              showMore: showMoreBoysUnder14,
              setShowMore: setShowMoreBoysUnder14,
            },
            {
              people: showMoreGirlsUnder12
                ? girlsUnder12
                : girlsUnder12.slice(0, 10),
              fieldName: "women_u12_points",
              showMore: showMoreGirlsUnder12,
              setShowMore: setShowMoreGirlsUnder12,
            },
            {
              people: showMoreBoysUnder12
                ? boysUnder12
                : boysUnder12.slice(0, 10),
              fieldName: "men_u12_points",
              showMore: showMoreBoysUnder12,
              setShowMore: setShowMoreBoysUnder12,
            },
          ]}
        />
      </>
    </Layout>
  );
};
export default Rankings;
